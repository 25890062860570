import Vue from "vue";
import Router from "vue-router";

// Containers
const DefaultContainer = () => import("@/containers/DefaultContainer");

// Views
const Dashboard = () => import("@/views/Dashboard/Dashboard");
const DashboardOvedue = () => import("@/views/Dashboard/DashboardOverdue");
const DashboardFinancial = () => import("@/views/Dashboard/DashboardFinancial");
const DashboardPBX = () => import("@/views/Dashboard/Operation/DashboardPBX");
const DashboardOperation = () =>
  import("@/views/Dashboard/Operation/Dashboard");

//Customer
const Customers = () => import("@/views/admin/Customer/Customer/List");
const XypInfo = () => import("@/views/admin/Customer/Customer/XypInfo");
const DanInfo = () => import("@/views/admin/Customer/Customer/DanInfo");
const CustomerDetail = () => import("@/views/admin/Customer/Customer/Detail");
const OverdueLoanClosing = () =>
  import("@/views/admin/Customer/Customer/OverdueLoanClosing");
const CustomerCreate = () => import("@/views/admin/Customer/Customer/New/Create");
const CustomerIncomeInfo = () => import("@/views/admin/Customer/Customer/IncomeInfo");

//Bank
const KhanDownload = () => import("@/views/admin/Bank/KhanDownload");

//Repayment
const LoanRepayments = () =>
  import("@/views/admin/Repayment/LoanRepayments/List");
const NewRepayments = () =>
  import("@/views/admin/Repayment/LoanRepayments/New");
const NewRepairRepayments = () =>
  import("@/views/admin/Repayment/LoanRepayments/NewRepair");
//Repayment Overdue
const LoanRepaymentsOverdue = () =>
  import("@/views/admin/Repayment/LoanRepaymentsOverdue/List");
  const LoanRepaymentsOverdueDownload = () =>
  import("@/views/admin/Repayment/LoanRepaymentsOverdue/Download");
const LoanRepaymentsOverdueCredit = () =>
  import("@/views/admin/Repayment/LoanRepaymentsOverdue/ListCredit");
//Repayment Error
const LoanRepaymentsErrorCode = () =>
  import("@/views/admin/Repayment/LoanRepaymentsErrorCode/List");
const LoanRepaymentsErrorCodeEdit = () =>
  import("@/views/admin/Repayment/LoanRepaymentsErrorCode/Edit");

//Loan Request Error
const LoanRequest = () => import("@/views/admin/Loan/LoanRequest/List");
const LoanRequestEdit = () => import("@/views/admin/Loan/LoanRequest/Edit");
//Loan Account
const CustomerLoanAccounts = () =>
  import("@/views/admin/Loan/CustomerLoanAccount/List");
const CustomerLoanAccountsNew = () =>
  import("@/views/admin/Loan/CustomerLoanAccount/New");
const CustomerLoanAccountsExtend = () =>
  import("@/views/admin/Loan/CustomerLoanAccount/Extend");
const CustomerLoanAccountsExtendGenerate = () =>
  import("@/views/admin/Loan/CustomerLoanAccount/ExtendGenerate");
const CustomerLoanAccountsDetail = () =>
  import("@/views/admin/Loan/CustomerLoanAccount/Detail");
const CustomerLoanAccountsDeposit = () =>
  import("@/views/admin/Loan/CustomerLoanAccount/Deposit");
const CustomerLoanAccountsDepositWoman = () =>
  import("@/views/admin/Loan/CustomerLoanAccount/DepositWoman");
const CustomerLoanAccountsDepositExtra = () =>
  import("@/views/admin/Loan/CustomerLoanAccount/DepositExtra");
  const CustomerLoanAccountsFinance = () =>
  import("@/views/admin/Loan/CustomerLoanAccount/Finance");
//Overdue Loan
const OverdueLoan = () => import("@/views/admin/Loan/OverdueLoan/List");
const NotCibLoan = () => import("@/views/admin/Loan/Cib/NotSent");
const CibChangeClassLoan = () => import("@/views/admin/Loan/Cib/ChangeClass");
const CibCloseLoan = () => import("@/views/admin/Loan/Cib/CibCloseLoan");
const CibGetPDF = () => import("@/views/admin/Loan/Cib/CibGetPDF");

//Report
const Report = () => import("@/views/admin/Report/List");
const ReportMonth = () => import("@/views/admin/Report/Month");
const ReportSeason = () => import("@/views/admin/Report/Season");
const ReportNote = () => import("@/views/admin/Report/Note");
const ReportWeek = () => import("@/views/admin/Report/Week");
const ReportPdfData = () => import("@/views/admin/Report/PdfData");
const ReportContract = () => import("@/views/admin/Report/Contract");
const ReportContractUsers = () => import("@/views/admin/Report/ContractUsers");

//Kiosk
const KioskPDFList = () => import("@/views/admin/Kiosk/pdf");

//Contract
const DigitalContractFile = () => import("@/views/admin/Contract/File");

//Purchase
const PurchaseMerchantList = () =>
  import("@/views/admin/Purchase/Merchant/List.vue");
const PurchaseMerchantEdit = () =>
  import("@/views/admin/Purchase/Merchant/Edit.vue");
const PurchaseMerchantConfig = () =>
  import("@/views/admin/Purchase/Merchant/Config.vue");

const PurchaseLoanRequestList = () =>
  import("@/views/admin/Purchase/Loan/RequestList.vue");
const PurchaseLoanList = () => import("@/views/admin/Purchase/Loan/List.vue");
const PurchaseInvoiceList = () =>
  import("@/views/admin/Purchase/Invoice/List.vue");
const PurchaseReportList = () =>
  import("@/views/admin/Purchase/Report/List.vue");

const PurchaseCustomerList = () =>
  import("@/views/admin/Purchase/Customer/List.vue");
const PurchaseRepaymentList = () =>
  import("@/views/admin/Purchase/Repayment/List.vue");
const PurchaseRepaymentPayment = () =>
  import("@/views/admin/Purchase/Repayment/Payment.vue");
const PurchaseOverdueList = () =>
  import("@/views/admin/Purchase/Overdue/List.vue");

const PurchaseMerchantCategoryList = () =>
  import("@/views/admin/Purchase/Merchant/Category/List.vue");
const PurchaseMerchantCategoryEdit = () =>
  import("@/views/admin/Purchase/Merchant/Category/Edit.vue");

//System Users
const SysUsers = () => import("@/views/system/Users/List");
const SysUsersEdit = () => import("@/views/system/Users/Edit");

//System Role
const SysRole = () => import("@/views/system/Role/List");
const SysRoleEdit = () => import("@/views/system/Role/Edit");

//System Menu
const SysMenu = () => import("@/views/system/Menu/List");
const SysMenuEdit = () => import("@/views/system/Menu/Edit");

//System Permission
const SysPermission = () => import("@/views/system/Permission/List");
const SysPermissionEdit = () => import("@/views/system/Permission/Edit");

// Views - Secure
const Page404 = () => import("@/views/secure/Page404");
const Page500 = () => import("@/views/secure/Page500");
const Login = () => import("@/views/secure/Login");
const Logout = () => import("@/views/secure/Logout");
const Register = () => import("@/views/secure/Register");
const ChangePassword = () => import("@/views/secure/ChangePassword");

const OldData = () => import("@/views/generate/OldData");
const ReInterest = () => import("@/views/generate/ReInterest");
const ReStar = () => import("@/views/generate/ReStar");
const RePayment = () => import("@/views/generate/RePayment");
const ReInterestError = () => import("@/views/generate/ReInterestError");
const XypCibData = () => import("@/views/generate/XypCibData");
const NoDtl = () => import("@/views/generate/NoDtl");
const NoNumurScore = () => import("@/views/generate/NoNumurScore");

const CreateLoan = () => import("@/views/test/CreateLoan");
const CreatePayment = () => import("@/views/test/CreatePayment");

const NotificationPush = () => import("@/views/admin/Notification/Push");

const DistributeConfig = () => import("@/views/system/Distribute/Config");

const ContractList = () => import("@/views/system/Contract/List");

const OperatorList = () => import("@/views/system/Operator/List");

const BannerList = () => import("@/views/system/Banner/List");

const StudentLoanRequestList = () => import("@/views/admin/Oyutan/List");
const StudentLoanRequestDetail = () => import("@/views/admin/Oyutan/Detail");

const AppNumurLoanRequestList = () => import("@/views/admin/AppNumur/List");
const AppNumurLoanRequestDetail = () => import("@/views/admin/AppNumur/Detail");

const DepositList = () => import("@/views/Dashboard/Deposit")
const DepositNew = () => import("@/views/admin/Deposit/New")
const DepositEdit = () => import("@/views/admin/Deposit/Edit")


const NplUerList = () => import("@/views/admin/Npl/List")
const SalesList = () => import("@/views/admin/Sales/List");
const SalesDetail = () => import("@/views/admin/Sales/Detail");


Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { path: "*", component: Page404 },
    { path: "/old-data", component: OldData },
    { path: "/re-interest", component: ReInterest },
    { path: "/re-star", component: ReStar },
    { path: "/re-payment/:date", component: RePayment },
    { path: "/re-interest-error", component: ReInterestError },
    { path: "/xyp-cib-data", component: XypCibData },
    { path: "/no-dtl", component: NoDtl },
    { path: "/create-loan-test", component: CreateLoan },
    { path: "/create-payment-test", component: CreatePayment },
    { path: "/no-numur-score/:date", component: NoNumurScore },
    {
      path: "/customer/:id/ticket-view",
      name: "admin.customer.ticket",
      meta: {
        label: "Дэлгэрэнгүй",
        permission: "admin.customer.detail",
      },
      component: CustomerDetail,
    },
    {
      path: "/",
      redirect: "/dashboard",
      name: "home",
      meta: {
        label: "Нүүр",
      },
      component: DefaultContainer,
      children: [
        { 
          path: "/npl/user_list", 
          name: "dashboard",
          meta: {
            label: "Хэрэглэгчид",
          },
          component: NplUerList 
        },
        {
          path: "dashboard",
          name: "dashboard",
          meta: {
            label: "Хянах самбар",
          },
          component: Dashboard,
        },
        {
          path: "dashboard-financial",
          name: "dashboard-financial",
          meta: {
            label: "Санхүүгийн хянах самбар",
          },
          component: DashboardFinancial,
        },
        {
          path: "dashboard-overdue",
          name: "dashboard-overdue",
          meta: {
            label: "Хугацаа хэтрэлт",
          },
          component: DashboardOvedue,
        },
        // {
        //   path: 'dashboard-pbx',
        //   name: 'dashboard-pbx',
        //   meta:{
        //     label: 'Дуудлагын жагсаалт'
        //   },
        //   component: DashboardPBX
        // },
        // {
        //   path: 'dashboard-operation',
        //   name: 'dashboard-operation',
        //   meta: {
        //     label: 'хянах самбар'
        //   },
        //   component: DashboardOperation
        // },
        {
          path: "admin",
          redirect: "/dashboard",
          name: "admin",
          meta: {
            label: "",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "deposit",
              redirect: "/dashboard",
              name: "deposit",
              meta: {
                label: "",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "list",
                  name: "deposit.list",
                  meta: {
                    label: "Барьцаа",
                  },
                  component: DepositList,
                },
                {
                  path: "new/:loanAccountId",
                  name: "deposit.new",
                  meta: {
                    label: "Барьцаа бүртгэх",
                  },
                  component: DepositNew,
                },
                {
                  path: ":id/edit",
                  name: "deposit.edit",
                  meta: {
                    label: "Барьцаа бүртгэх",
                  },
                  component: DepositEdit,
                },
              ],
            },
            {
              path: "bank",
              redirect: "/dashboard",
              name: "admin.bank",
              meta: {
                label: "Банк",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "khan-statement",
                  name: "admin.bank.statement",
                  meta: {
                    label: "Хаан хуулга",
                    permission: "admin.bank.statement",
                  },
                  component: KhanDownload,
                },
              ]
            },
            {
              path: "customers",
              redirect: "/admin/customers",
              name: "admin.customer",
              meta: {
                label: "Хэрэглэгч",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "admin.customer.list",
                  meta: {
                    label: "",
                    permission: "admin.customer.list",
                  },
                  component: Customers,
                },
                {
                  path: "create",
                  name: "admin.customer.create",
                  meta: {
                    label: "Шинээр бүртгэх",
                    permission: "admin.customer.create",
                  },
                  component: CustomerCreate,
                },
                {
                  path: ":id/income-info",
                  name: "admin.customer.income_info",
                  meta: {
                    label: "Эрх тогтохх",
                    permission: "admin.customer.income_info",
                  },
                  component: CustomerIncomeInfo,
                },
                {
                  path: ":id/xyp",
                  name: "admin.customer.xyp",
                  meta: {
                    label: "ХУР",
                    permission: "admin.customer.xyp",
                  },
                  component: XypInfo,
                },
                {
                  path: ":id/dan",
                  name: "admin.customer.dan",
                  meta: {
                    label: "ДАН",
                    permission: "admin.customer.xyp",
                  },
                  component: DanInfo,
                },
                {
                  path: ":id/detail",
                  name: "admin.customer.detail",
                  meta: {
                    label: "Дэлгэрэнгүй",
                    permission: "admin.customer.detail",
                  },
                  component: CustomerDetail,
                },
                {
                  path: ":id/closing",
                  name: "admin.customer.closing",
                  meta: {
                    label: "Илүү төлөлтөөр зээл хаах",
                    permission: "admin.customer.closing",
                  },
                  component: OverdueLoanClosing,
                },
              ],
            },
            {
              path: "customer-loan-accounts",
              name: "admin.customer-loan-accounts",
              meta: {
                label: "Зээлийн данс",
                permission: "admin.customer-loan-accounts.list",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "admin.customer-loan-accounts.list",
                  meta: {
                    label: "",
                    permission: "admin.customer-loan-accounts.list",
                  },
                  component: CustomerLoanAccounts,
                },
                {
                  path: "extend",
                  name: "admin.customer-loan-accounts.extend",
                  meta: {
                    label: "Зээл сунгах",
                    permission: "admin.loan.extend",
                  },
                  component: CustomerLoanAccountsExtend,
                },
                {
                  path: "extend-generate",
                  name: "admin.customer-loan-accounts.extend-generate",
                  meta: {
                    label: "Зээл сунгах тооцоолуур",
                  },
                  component: CustomerLoanAccountsExtendGenerate,
                },
                {
                  path: "new",
                  name: "admin.customer-loan-accounts.new",
                  meta: {
                    label: "Шинээр зээл үүсгэх",
                    permission: "admin.customer-loan-accounts.new",
                  },
                  component: CustomerLoanAccountsNew,
                },
                {
                  path: ":id/detail",
                  name: "admin.customer-loan-accounts.detail",
                  meta: {
                    label: "Дэлгэрэнгүй",
                    permission: "admin.customer-loan-accounts.detail",
                  },
                  component: CustomerLoanAccountsDetail,
                },
                {
                  path: ":id/deposit-new",
                  name: "admin.customer-loan-accounts.deposit-new",
                  meta: {
                    label: "Барьцаа",
                    permission: "admin.customer-loan-accounts.new",
                  },
                  component: CustomerLoanAccountsDeposit,
                },
                {
                  path: ":id/deposit-woman-new",
                  name: "admin.customer-loan-accounts.deposit-woman-new",
                  meta: {
                    label: "Барьцаа",
                    permission: "admin.customer-loan-accounts.woman-new",
                  },
                  component: CustomerLoanAccountsDepositWoman,
                },
                {
                  path: ":id/deposit-extra-new",
                  name: "admin.customer-loan-accounts.deposit-extra-new",
                  meta: {
                    label: "Нэмэлт барьцаа",
                    permission: "admin.customer-loan-accounts.new",
                  },
                  component: CustomerLoanAccountsDepositExtra,
                },
                {
                  path: ":id/finance-new",
                  name: "admin.customer-loan-accounts.finance-new",
                  meta: {
                    label: "Санхүүгийн мэдээлэл",
                    permission: "admin.customer-loan-accounts.new",
                  },
                  component: CustomerLoanAccountsFinance,
                },
              ],
            },
            {
              path: "loan-repayments",
              redirect: "/admin/loan-repayments",
              name: "admin.loan-repayments",
              meta: {
                label: "Эргэн төлөлт",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "admin.loan-repayments.list",
                  meta: {
                    label: "",
                    permission: "admin.loan-repayments.list",
                  },
                  component: LoanRepayments,
                },
                {
                  path: "new",
                  name: "admin.loan-repayments.new",
                  meta: {
                    label: "Төлбөр бүртгэх",
                    permission: "admin.loan-repayments.new",
                  },
                  component: NewRepayments,
                },
                {
                  path: "repair",
                  name: "admin.loan-repayments.repair",
                  meta: {
                    label: "Засварын гүйлгээ бүртгэх",
                    permission: "admin.loan-repayments.repair",
                  },
                  component: NewRepairRepayments,
                },
              ],
            },
            {
              path: "loan-repayments-overdue",
              name: "admin.loan-repayments-overdue",
              meta: {
                label: "Хугацаа хэтрэлт",
                permission: "admin.loan-repayments-overdue.list",
              },
              component: LoanRepaymentsOverdue,
            },
            {
              path: "loan-repayments-overdue-download",
              name: "admin.loan-repayments-overdue-download",
              meta: {
                label: "",
                permission: "admin.loan-repayments-overdue.download",
              },
              component: LoanRepaymentsOverdueDownload,
            },
            {
              path: "student",
              redirect: "/admin/student",
              name: "admin.student",
              meta: {
                label: "Оюутаны зээл",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "admin.student.loan-request",
                  meta: {
                    label: "Зээлийн хүсэлт",
                    permission: "admin.student.loan-request",
                  },
                  component: StudentLoanRequestList,
                },
                {
                  path: ":id/detail",
                  name: "admin.student.loan-request.detail",
                  meta: {
                    label: "Зээлийн хүсэлт дэлгэрэнгүй",
                    permission: "admin.student.loan-request",
                  },
                  component: StudentLoanRequestDetail,
                },
              ],
            },
            {
              path: "app-numur",
              redirect: "/admin/app-numur/loan-request",
              name: "admin.app-numur",
              meta: {
                label: "Апп нөмөр зээлийн хүсэлт"
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "loan-request",
                  name: "admin.app-numur.loan-request",
                  meta: {
                    label: "Апп нөмөр зээлийн хүсэлт",
                    permission: "admin.app-numur.loan-request",
                  },
                  component: AppNumurLoanRequestList,
                },
                {
                  path: "loan-request/:id/detail",
                  name: "admin.app-numur.loan-request.detail",
                  meta: {
                    label: "Апп нөмөр зээлийн хүсэлт",
                    permission: "admin.app-numur.loan-request",
                  },
                  component: AppNumurLoanRequestDetail,
                }
              ],
            },
            {
              path: "loan-repayments-overdue-credit",
              name: "admin.loan-repayments-overdue-credit",
              meta: {
                label: "Хугацаа хэтрэлт /Уламжлалт/",
                permission: "admin.loan-repayments-overdue.list",
              },
              component: LoanRepaymentsOverdueCredit,
            },
            {
              path: "loan-repayments-error-code",
              redirect: "/admin/loan-repayments-error-code",
              name: "admin.loan-repayments-error-code",
              meta: {
                label: "Алдаатай эргэн төлөлт",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "admin.loan-repayments-error-code.list",
                  meta: {
                    label: "",
                    permission: "admin.loan-repayments-error-code.list",
                  },
                  component: LoanRepaymentsErrorCode,
                },
                {
                  path: ":id/edit",
                  name: "admin.loan-repayments-error-code.edit",
                  meta: {
                    label: "Засах",
                    permission: "admin.loan-repayments-error-code.edit",
                  },
                  component: LoanRepaymentsErrorCodeEdit,
                },
              ],
            },
            {
              path: "loan-request",
              redirect: "/admin/loan-request",
              name: "admin.loan-request",
              meta: {
                label: "Зээлийн хүсэлт",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "admin.loan-request.list",
                  meta: {
                    label: "",
                    permission: "admin.loan-request.list",
                  },
                  component: LoanRequest,
                },
                {
                  path: ":id/edit",
                  name: "admin.loan-request.edit",
                  meta: {
                    label: "Засах",
                    permission: "admin.loan-request.edit",
                  },
                  component: LoanRequestEdit,
                },
              ],
            },
            {
              path: "overdue-loan",
              redirect: "/admin/overdue-loan",
              name: "admin.overdue-loan",
              meta: {
                label: "Хугацаа хэтэрсэн зээл",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "admin.overdue-loan.list",
                  meta: {
                    label: "",
                    permission: "admin.overdue-loan.list",
                  },
                  component: OverdueLoan,
                },
              ],
            },
            {
              path: "contract",
              redirect: "/admin/contract",
              name: "admin.contract",
              meta: {
                label: "Contract",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "file",
                  name: "admin.contract.file",
                  meta: {
                    label: "Гэрээний жагсаалт",
                    permission: "admin.contract.file",
                  },
                  component: DigitalContractFile,
                },
              ],
            },
            {
              path: "kiosk",
              redirect: "/admin/kiosk",
              name: "admin.kiosk",
              meta: {
                label: "Киоск",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "pdf",
                  name: "admin.kiosk.pdf",
                  meta: {
                    label: "Гэрээний жагсаалт",
                    permission: "admin.kiosk.pdf",
                  },
                  component: KioskPDFList,
                },
              ],
            },
            {
              path: "cib",
              redirect: "/admin/cib",
              name: "admin.cib",
              meta: {
                label: "",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "not-sent",
                  name: "admin.cib.not-sent",
                  meta: {
                    label: "ЗМС илгээх",
                    permission: "admin.cib.not-sent",
                  },
                  component: NotCibLoan,
                },
                {
                  path: "change-class",
                  name: "admin.cib.change-class",
                  meta: {
                    label: "ЗМС ангилал өөрчлөх",
                    permission: "admin.cib.change-class",
                  },
                  component: CibChangeClassLoan,
                },
                {
                  path: "close-sent",
                  name: "admin.cib.close-sent",
                  meta: {
                    label: "ЗМС зээл хаах",
                    permission: "admin.cib.close-sent",
                  },
                  component: CibCloseLoan,
                },
                {
                  path: "get-pdf",
                  name: "admin.cib.get-pdf",
                  meta: {
                    label: "PDF татах",
                    permission: "admin.cib.get-pdf",
                  },
                  component: CibGetPDF,
                },
              ],
            },
            {
              path: "report",
              redirect: "/admin/report",
              name: "admin.report",
              meta: {
                label: "Тайлан",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "admin.report.list",
                  meta: {
                    label: "",
                    permission: "admin.report.list",
                  },
                  component: Report,
                },
                {
                  path: "month",
                  name: "admin.report.month",
                  meta: {
                    label: "Сарын тайлан",
                    permission: "admin.report.month",
                  },
                  component: ReportMonth,
                },
                {
                  path: "season",
                  name: "admin.report.season",
                  meta: {
                    label: "Улирлын тайлан",
                    permission: "admin.report.season",
                  },
                  component: ReportSeason,
                },
                {
                  path: "note",
                  name: "admin.report.note",
                  meta: {
                    label: "Дотоод хяналтын тайлан",
                    permission: "admin.report.note",
                  },
                  component: ReportNote,
                },
                {
                  path: "week",
                  name: "admin.report.week",
                  meta: {
                    label: "7 хонгийн тайлан",
                    permission: "admin.report.week",
                  },
                  component: ReportWeek,
                },
                {
                  path: "pdf-data",
                  name: "admin.report.pdf-data",
                  meta: {
                    label: "PDF",
                    permission: "admin.report.pdf-data",
                  },
                  component: ReportPdfData,
                },
                {
                  path: "contract",
                  name: "admin.report.contract",
                  meta: {
                    label: "Гэрээний тайлан",
                    permission: "admin.report.contract",
                  },
                  component: ReportContract
                },
                {
                  path: "id:contract-users",
                  name: "admin.report.contract-users",
                  meta: {
                    label: "Хэрэглэгчид",
                    permission: "admin.report.contract",
                  },
                  component: ReportContractUsers
                }
              ],
            },
            {
              path: "sales",
              redirect: "/admin/sales",
              name: "admin.sales",
              meta: {
                label: "Sales",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "admin.sales.list",
                  meta: {
                    label: "",
                    permission: "admin.sales.list",
                  },
                  component: SalesList,
                },
                {
                  path: ":id/detail",
                  name: "admin.sales.detail",
                  meta: {
                    label: "Дэлгэрэнгүй",
                    permission: "admin.sales.detail",
                  },
                  component: SalesDetail,
                },
              ],
            },
            {
              path: "purchase",
              redirect: "/admin/purchase",
              name: "admin.purchase",
              meta: {
                label: "",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "merchant",
                  name: "admin.purchase.merchant",
                  meta: {
                    label: "",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "admin.purchase.merchant.list",
                      meta: {
                        label: "Merchant list",
                        permission: "admin.purchase.merchant.list",
                      },
                      component: PurchaseMerchantList,
                    },
                    {
                      path: ":id/edit",
                      name: "admin.purchase.merchant.edit",
                      meta: {
                        label: "Засах",
                        permission: "admin.purchase.merchant.edit",
                      },
                      component: PurchaseMerchantEdit,
                    },
                    {
                      path: ":id/config/:loanTypeId?/:conditionId?",
                      name: "admin.purchase.merchant.config",
                      meta: {
                        label: "Config",
                        permission: "admin.purchase.merchant.config",
                      },
                      props: (route) => ({
                        cusgId: route.params.cusgId || "0",
                      }),
                      component: PurchaseMerchantConfig,
                    },
                    {
                      path: "category",
                      name: "admin.purchase.merchant.category",
                      meta: {
                        label: "Merchant list",
                        permission: "admin.purchase.merchant.list",
                      },
                      component: {
                        render(c) {
                          return c("router-view");
                        },
                      },
                      children: [
                        {
                          path: "",
                          name: "admin.purchase.merchant.category.list",
                          meta: {
                            label: "Merchant Category list",
                            permission: "admin.purchase.merchant.category.list",
                          },
                          component: PurchaseMerchantCategoryList,
                        },
                        {
                          path: ":id/edit",
                          name: "admin.purchase.merchant.category.edit",
                          meta: {
                            label: "Засах",
                            permission: "admin.purchase.merchant.category.edit",
                          },
                          component: PurchaseMerchantCategoryEdit,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "loan",
                  name: "admin.purchase.loan",
                  meta: {
                    label: "",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "request",
                      name: "admin.purchase.loan.request",
                      meta: {
                        label: "Зээлийн хүсэлтүүд",
                        permission: "admin.purchase.loan.request",
                      },
                      component: PurchaseLoanRequestList,
                    },
                    {
                      path: "",
                      name: "admin.purchase.loan.list",
                      meta: {
                        label: "Зээлийн данс",
                        permission: "admin.purchase.loan.list",
                      },
                      component: PurchaseLoanList,
                    },
                  ],
                },
                {
                  path: "invoice",
                  name: "admin.purchase.invoice",
                  meta: {
                    label: "",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "admin.purchase.invoice.list",
                      meta: {
                        label: "Нэхэмжлэх",
                        permission: "admin.purchase.invoice.list",
                      },
                      component: PurchaseInvoiceList,
                    },
                  ],
                },
                {
                  path: "report",
                  name: "admin.purchase.report",
                  meta: {
                    label: "",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "admin.purchase.report.list",
                      meta: {
                        label: "Тайлан",
                        permission: "admin.purchase.report.list",
                      },
                      component: PurchaseReportList,
                    },
                  ],
                },
                {
                  path: "customer",
                  name: "admin.purchase.customer",
                  meta: {
                    label: "",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "admin.purchase.customer.list",
                      meta: {
                        label: "Хэрэглэгч",
                        permission: "admin.purchase.customer.list",
                      },
                      component: PurchaseCustomerList,
                    },
                  ],
                },
                {
                  path: "repayment",
                  name: "admin.purchase.repayment",
                  meta: {
                    label: "",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "admin.purchase.repayment.list",
                      meta: {
                        label: "Эргэн төлөлт",
                        permission: "admin.purchase.repayment.list",
                      },
                      component: PurchaseRepaymentList,

                    },
                    {
                      path: "payment",
                      name: "admin.purchase.repayment.payment",
                      meta: {
                        label: "Төлөлт",
                        permission: "admin.purchase.repayment.payment",
                      },
                      component: PurchaseRepaymentPayment,

                    },
                  ],
                },
                {
                  path: "overdue",
                  name: "admin.purchase.overdue",
                  meta: {
                    label: "",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "admin.purchase.overdue.list",
                      meta: {
                        label: "Хугацаа хэтрэлт",
                        permission: "admin.purchase.repayment.list",
                      },
                      component: PurchaseOverdueList,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "notification",
          redirect: "/dashboard",
          name: "notification",
          meta: {
            label: "",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "push",
              name: "notification.push",
              meta: {
                label: "Цэс",
              },
              component: NotificationPush,
            },
          ],
        },
        {
          path: "contract",
          redirect: "/dashboard",
          name: "contract",
          meta: {
            label: "",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "number",
              name: "contract.number",
              meta: {
                label: "Гэрээ",
              },
              component: DepositList,
            },
          ],
        },
        {
          path: "distribute",
          redirect: "/dashboard",
          name: "distribute",
          meta: {
            label: "",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "config/:id",
              name: "distribute.config",
              meta: {
                label: "Цэс",
              },
              component: DistributeConfig,
            },
          ],
        },
        {
          path: "system",
          redirect: "/dashboard",
          name: "system",
          meta: {
            label: "",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "menu",
              redirect: "/system/menu",
              name: "system.menu",
              meta: {
                label: "Цэс",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "system.menu.list",
                  meta: {
                    label: "",
                    permission: "system.menu.list",
                  },
                  component: SysMenu,
                },
                {
                  path: ":id/edit",
                  name: "system.menu.edit",
                  meta: {
                    label: "Загвар",
                    permission: "system.menu.edit",
                  },
                  component: SysMenuEdit,
                },
              ],
            },
            {
              path: "users",
              redirect: "/system/users",
              name: "system.users",
              meta: {
                label: "Ажилтан",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "system.users.list",
                  meta: {
                    label: "",
                    permission: "system.users.list",
                  },
                  component: SysUsers,
                },
                {
                  path: ":id/edit",
                  name: "system.users.edit",
                  meta: {
                    label: "Загвар",
                    permission: "system.users.edit",
                  },
                  component: SysUsersEdit,
                },
              ],
            },
            {
              path: "role",
              redirect: "/system/role",
              name: "system.role",
              meta: {
                label: "Хандах эрх",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "system.role.list",
                  meta: {
                    label: "",
                    permission: "system.role.list",
                  },
                  component: SysRole,
                },
                {
                  path: ":id/edit",
                  name: "system.role.edit",
                  meta: {
                    label: "Загвар",
                    permission: "system.role.edit",
                  },
                  component: SysRoleEdit,
                },
              ],
            },
            {
              path: "operator",
              redirect: "/system/operator",
              name: "system.operator",
              meta: {
                label: "Admin app эрх",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "system.operator.list",
                  meta: {
                    label: "",
                    permission: "system.operator.list",
                  },
                  component: OperatorList,
                },
                // {
                //   path: ':id/edit',
                //   name: 'system.role.edit',
                //   meta: {
                //     label: 'Загвар',
                //     permission: 'system.role.edit'
                //   },
                //   component: SysRoleEdit
                // },
              ],
            },
            {
              path: "banner",
              redirect: "/system/banner",
              name: "system.banner",
              meta: {
                label: "Banner",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "system.banner.list",
                  meta: {
                    label: "",
                    permission: "system.banner.list",
                  },
                  component: BannerList,
                },
                // {
                //   path: ':id/edit',
                //   name: 'system.role.edit',
                //   meta: {
                //     label: 'Загвар',
                //     permission: 'system.role.edit'
                //   },
                //   component: SysRoleEdit
                // },
              ],
            },
            {
              path: "permission",
              redirect: "/system/permission",
              name: "system.permission",
              meta: {
                label: "Permission",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "system.permission.list",
                  meta: {
                    label: "",
                    permission: "system.permission.list",
                  },
                  component: SysPermission,
                },
                {
                  path: ":id/edit",
                  name: "system.permission.edit",
                  meta: {
                    label: "Загвар",
                    permission: "system.permission.edit",
                  },
                  component: SysPermissionEdit,
                },
              ],
            },
          ],
        },
        {
          path: "profile",
          redirect: "/dashboard",
          name: "profile",
          meta: {
            label: "",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "change-password",
              name: "profile.change_password",
              meta: {
                label: "Нууц үг солих",
              },
              component: ChangePassword,
            },
          ],
        },
      ],
    },
    {
      path: "/secure",
      redirect: "/secure/404",
      name: "Secure",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "logout",
          name: "Logout",
          component: Logout,
        },
        {
          path: "register",
          name: "Register",
          component: Register,
        },
      ],
    },
  ],
});
