import Vue from "vue";
import VueConfig from "vue-config";
import moment from "moment";
import { throws } from "assert";
import { kMaxLength } from "buffer";
const crypto = require("crypto");

export const KEY = "EncryptKey123456";

function decrypt_data(data) {
  try {
    data = data.replace(" ", "+");
    const iv = Buffer.alloc(16, 0);
    var cipher = crypto.createDecipheriv("aes-128-cbc", KEY, iv);
    var str = decodeURI(
      cipher.update(data, "base64", "utf8") + cipher.final("utf8")
    );
    return str.replaceAll("+", " ");
  } catch (error) {
    //console.log("DECRYPT ERROR - " + error);
  }
  return null;
}

export function encrypt_data(data) {
  try {
    const iv = Buffer.alloc(16, 0);
    const cipher = crypto.createCipheriv("aes-128-cbc", KEY, iv);
    return cipher.update(data, "utf8", "base64") + cipher.final("base64");
  } catch (error) {
    //console.log("ENCRYPT ERROR - " + error);
  }
  return null;
}

export default function formatNumber(num, fixed = 0) {
  return num
    .toFixed(fixed)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'");
}

Vue.use(VueConfig, {
  // API_URL: "http://192.168.0.204:8080/project.admin/rest/Backend/",
  // APP_URL: "http://192.168.0.204:8080/wallet/webresources/App/",

  // TEST SERVER
  // API_URL: "http://172.16.23.117:8080/numurappadmin/rest/Backend/",
  // APP_URL: "http://172.16.23.117:8080/numurapp/webresources/App/",
  // APP_URL_1: "http://172.16.23.117:8080/numurappbank/rest/Bank/",
  // ADMIN_API_URL: "http://172.16.23.117:8080/numurappadmin/rest/",
  // API_TEST_URL: "http://172.16.23.117:8080/numurapptest/rest/Backend/",

  // // LOCAL SERVER
  // API_URL: "http://localhost:8080/numurappadmin/rest/Backend/",
  // APP_URL: "http://localhost:8080/wallet/webresources/App/",
  // APP_URL_1: "http://localhost:8080/numurappadmin/rest/Bank/",
  // ADMIN_API_URL: "http://localhost:8080/numurappadmin/rest/",
  // API_TEST_URL: "http://localhost:8080/numurapptest/rest/Backend/",

  // PRODUCTION SERVER
  API_URL: "https://numur.mn/numurappbackend/rest/Backend/",
  APP_URL: "https://numur.mn/wallet/webresources/App/",
  APP_URL_1: "https://numur.mn/numurappbackend/rest/Bank/",
  ADMIN_API_URL: "https://numur.mn/numurappbackend/rest/",
  API_TEST_URL: "https://numur.mn/numurapptest/rest/Backend/",
  API_SERVICE_URL: "https://numur.mn/numurappbackend/rest/Backend/",

  FB_GRAPH_URL: "https://graph.facebook.com/me?access_token=",

  API_NUMUR_URL: "https://app.numur.mn/",
  // API_NUMUR_URL: 'http://172.16.23.159:8000/',
  BOT_URL: "https://admin.numur.mn/bots/bot.php/",
  REPORT_NUMUR_URL: "https://report.numur.mn/",
  // REPORT_NUMUR_URL: 'http://127.0.0.1:5000/',

  // API_URL: "http://namir.numur.mn/numurappadmin/rest/Backend/",
  // APP_URL: "http://namir.numur.mn/numurapp/webresources/App/",

  // OYUTAN_API_URL: "https://oyutan.numur.mn/numurappbackend/rest/",
  OYUTAN_API_URL: "https://oyutan.numur.mn/",
  // OYUTAN_API_URL: "http://172.16.23.168:8000/",

  APP_NUMUR_URL: "https://service.numur.mn/appnumur/",
  APP_NUMUR_S3_URL: "https://s3.eu-west-1.amazonaws.com/app.numur.mn/",

  // micro service url
  TEST_NUMUR_MS_URL: "https://test-svc.numur.mn/",
  TEST_NUMUR_MS_SALES_URL: "https://test-svc.numur.mn/sales/",

  NUMUR_MS_URL: "https://servicek.numur.mn/",
  NUMUR_MS_ADMIN_URL: "https://servicek.numur.mn/admin/",

  PARSE: {
    URL: "https://parseapi.back4app.com/classes/",
    APP_ID: "RS4ew79XjWp1BmJKoa36lrOvlmbogZdvUS6nNs6Z",
    KEY: "9aWogdmVdx9V6I1M5SvQiJ4ln3I5UqjKfsvNhd1y",
  },
  DATE: {
    datetime: { format: "YYYY-MM-DD HH:mm:ss", useCurrent: false },
    date: { format: "YYYY-MM-DD", useCurrent: false },
  },
  LOADER: {
    backgroundColor: "white",
    opacity: 0.7,
    color: "#037155",
  },
  LOAN_GROUP_LIST: [
    {
      text: "Хэрэглээний зээл",
      value: "APP",
    },
    {
      text: "Худалдан авалтын зээл",
      value: "PURCHASE",
    },
    {
      text: "Хугацаа сунгасан",
      value: "TIME_EXTEND",
    },
    {
      text: "Уламжлалт зээл",
      value: "CREDIT",
    },
    {
      text: "Дугаар барьцаалтсан",
      value: "PHONE_NUMBER",
    },
    {
      text: "Авто машин",
      value: "AUTO_MACHINE",
    },
    {
      text: "Үлдэгдэл бууруулах",
      value: "LOAN_BALANCE",
    },
    {
      text: "Хүүний зээл",
      value: "INTEREST_LOAN",
    },
    {
      text: "Марс зээл",
      value: "MARS_LOAN",
    },
    {
      text: "Марс зээл хугацаа хэтэрсэн",
      value: "MARS_LOAN_OVERDUE",
    },
    {
      text: "Оюутаны төлбөрийн зээл",
      value: "STUNDET_LOAN",
    },
    {
      text: "Бичил бизнесийн зээл",
      value: "SMALL_BUSINESS",
    },
    {
      text: "Сэргээсэн зээл",
      value: "REACTIVE_LOAN",
    },
    {
      text: "Олон улсын зээл",
      value: "INTERNATIONAL_LOAN",
    },
    {
      text: "Тариаланчидын зээл",
      value: "FARMER_LOAN",
    },
  ],
  CHART_OPTIONS: {
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      animationDuration: 1,
    },
    animation: {
      duration: 1,
      onComplete: function() {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;

        ctx.font = Chart.helpers.fontString(
          Chart.defaults.global.defaultFontSize + 1,
          "400",
          Chart.defaults.global.defaultFontFamily
        );
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";

        this.data.datasets.forEach(function(dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function(bar, index) {
            if (dataset.data[index] >= 1000000) {
              ctx.fillText(
                formatNumber(parseFloat(dataset.data[index] / 1000000), 2) +
                  " сая",
                bar._model.x,
                bar._model.y - 5
              );
            } else {
              ctx.fillText(
                formatNumber(parseFloat(dataset.data[index])),
                bar._model.x,
                bar._model.y - 5
              );
            }
          });
        });
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {
        labelColor: function(tooltipItem, chart) {
          return {
            backgroundColor:
              chart.data.datasets[tooltipItem.datasetIndex].borderColor,
          };
        },
        label: function(tooltipItem, chart) {
          if (tooltipItem.value >= 1000000) {
            return formatNumber(tooltipItem.value / 1000000, 2) + " сая";
          }
          return formatNumber(tooltipItem.value);
        },
      },
    },
    scales: {
      yAxes: [
        {
          beforeFit: function(scale) {
            let maxValue = 0;
            let minValue = -1;
            if (
              scale.chart.config &&
              scale.chart.config.data &&
              scale.chart.config.data.datasets
            ) {
              scale.chart.config.data.datasets.forEach((dataset) => {
                if (dataset && dataset.data) {
                  dataset.data.forEach((value) => {
                    if (value > maxValue) {
                      maxValue = value;
                    }

                    if (value < minValue || minValue == -1) {
                      minValue = value;
                    }
                  });
                }
              });
            }
            scale.options.ticks.max = maxValue * 1.1;
            scale.options.ticks.min =
              Math.round((minValue * 0.7) / 10000) * 10000;
          },
          afterTickToLabelConversion: function(scaleInstance) {
            scaleInstance.ticks[0] = null;
            scaleInstance.ticksAsNumbers[0] = null;

            var diff =
              scaleInstance.ticksAsNumbers[1] - scaleInstance.ticksAsNumbers[2];
            var lastIndex = scaleInstance.ticks.length - 1;
            if (
              scaleInstance.ticksAsNumbers[lastIndex - 1] -
                scaleInstance.ticksAsNumbers[lastIndex] <
              diff * 0.6
            ) {
              scaleInstance.ticks[lastIndex] = null;
              scaleInstance.ticksAsNumbers[lastIndex] = null;
            }
          },
          ticks: {
            beginAtZero: true,
            callback: function(label, index, labels) {
              if (label >= 1000000) {
                return formatNumber(label / 1000000, 1) + " сая";
              }
              if (label < 1) {
                return formatNumber(label, 1);
              }
              return formatNumber(label);
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  },
  DATERANGE: {
    LOCALE: {
      separator: " - ",
      format: "YYYY-MM-DD",
      customRangeLabel: "Өдөр сонгох",
      daysOfWeek: ["Ня", "Да", "Мя", "Лх", "Пү", "Ба", "Бя"],
      monthNames: [
        "1-р сар",
        "2-р сар",
        "3-р сар",
        "4-р сар",
        "5-р сар",
        "6-р сар",
        "7-р сар",
        "8-р сар",
        "9-р сар",
        "10-р сар",
        "11-р сар",
        "12-р сар",
      ],
      firstDay: 1,
    },
    RANGES: {
      Өнөөдөр: [moment(), moment()],
      Өчигдөр: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Сүүлийн 30 хоног": [moment().subtract(29, "days"), moment()],
      "Сүүлийн 90 хоног": [moment().subtract(89, "days"), moment()],
      "Энэ сар": [moment().startOf("month"), moment().endOf("month")],
      "Өмнөх сар": [
        moment()
          .subtract(1, "month")
          .startOf("month"),
        moment()
          .subtract(1, "month")
          .endOf("month"),
      ],
    },
  },
});

Vue.mixin({
  data: function() {
    return {
      LOAN_GROUP: {
        APP: "Хэрэглээний зээл",
        PURCHASE: "Худалдан авалтын зээл",
        TIME_EXTEND: "Хугацаа сунгасан",
        CREDIT: "Уламжлалт зээл",
        PHONE_NUMBER: "Дугаар барьцаалтсан зээл",
        AUTO_MACHINE: "Авто машин",
        LOAN_BALANCE: "Үлдэгдэл бууруусан",
        INTEREST_LOAN: "Хүүний зээл",
        MARS_LOAN: "Марс зээл",
        MARS_LOAN_OVERDUE: "Марс зээл хугацаа хэтэрсэн",
        STUNDET_LOAN: "Оюутаны төлбөрийн зээл",
        SMALL_BUSINESS: "Бичил бизнесийн зээл",
        REACTIVE_LOAN: "Сэргээсэн зээл",
        INTERNATIONAL_LOAN: "Олон улсын зээл",
        FARMER_LOAN: "Тариаланчидын зээл",
      },
      EXTRA_DEPOSIT_TYPE: {
        APARTMENT: "Орон сууц",
        FENCE_HOUSE: "Хашаа байшин",
        HOUSE: "Амины сууц",
        PLACE_OWNERSHIP: "Өмчлөх эрхтэй газар",
        PLACE_OWN: "Эзэмших эрхтэй газар",
      },
      EXTRA_DEPOSIT_OWNERSHIP: {
        OWN: "Өөрийн",
        PARTNER: "Хамтрангийн",
        CREDENTIAL: "Итгэмжлэлийн",
      },
    };
  },
  methods: {
    isEmpty(someObject) {
      return Object.keys(someObject).length == 0;
    },
    changeNavs: function() {
      this.$data.nav = [
        {
          name: "Хянах самбар",
          url: "/dashboard",
          icon: "icon-speedometer",
        },
        // {
        //   name: 'PBX dashboard',
        //   url: '/dashboard-pbx',
        //   icon: 'icon-user'
        // }
      ];

      let _roles = ["ADMIN", "SUPER_ADMIN", "EXECUTIVE", "LOAN_OFFICER_2"];
      let _user = this.$store.getters.user;

      if (
        [
          "ADMIN",
          "SUPER_ADMIN",
          "OPERATOR_PLUS",
          "OPERATOR",
          "SUPPORT",
          "COMPLAIN_SUB",
          "COMPLAIN",
          "ECONOMIST"
        ].includes(_user.role.code)
      ) {
        this.$data.nav.push({
          name: "Call",
          url: "/dashboard-overdue",
          icon: "icon-phone",
        });
      }

      if (_roles.includes(_user.role.code)) {
        this.$data.nav.push({
          name: "Санхүүгийн хянах самбар",
          url: "/dashboard-financial",
          icon: "icon-graph",
        });
      }

      this.$data.nav.push({
        title: true,
        name: "Үндсэн",
        class: "",
        wrapper: {
          element: "",
          attributes: {},
        },
      });

      let childrens = {};
      if (this.$store.getters.menu) {
        let _menus = this.$store.getters.menu;
        for (let i in _menus) {
          this.$data.nav.push(_menus[i]);
        }
      }
      if (this.$store.getters.user.role.code !== "AUDIT") this.adminNavs();
      // //console.log(this.$data.nav);
    },
    adminNavs: function() {
      let _roles = ["ADMIN", "SUPER_ADMIN", "SUPPORT"];
      let _user = this.$store.getters.user;
      let isHead = false;
      if (_roles.includes(_user.role.code)) {
        this.$data.nav.push({
          title: true,
          name: "Систем",
          class: "",
          wrapper: {
            element: "",
            attributes: {},
          },
        });
        isHead = true;
        this.$data.nav.push({
          name: "Цэс",
          url: "/system/menu",
          icon: "icon-menu",
        });
        this.$data.nav.push({
          name: "Ажилтан",
          icon: "icon-people",
          url: "/system/parent",
          children: [
            {
              name: "Хандах эрх",
              url: "/system/role",
              icon: "icon-key",
            },
            {
              name: "Ажилтан",
              url: "/system/users",
              icon: "icon-people",
            },
            {
              name: "Permission",
              url: "/system/permission",
              icon: "icon-key",
            },
          ],
        });
      }
      if (!isHead) {
        this.$data.nav.push({
          title: true,
          name: "Систем",
          class: "",
          wrapper: {
            element: "",
            attributes: {},
          },
        });
        isHead = true;
      }
      var settings_menu_list = [
        {
          name: "Гэрээ",
          icon: "icon-doc",
          url: "/contract/number",
        },
      ];
      _roles.push("CEO_MANAGER");
      if (_roles.includes(_user.role.code)) {
        settings_menu_list.push({
          name: "Ажилтан хувиарлах",
          url: "/distribute/config/0",
          icon: "icon-basket",
        });
      }
      _roles.push("MARKETING");
      _roles.push("MERCHANT_MANAGER");
      _roles.push("CEO_MANAGER");
      if (_roles.includes(_user.role.code)) {
        settings_menu_list.push({
          name: "Admin app нэвтрэх",
          url: "/system/operator",
          icon: "icon-basket",
        });
        settings_menu_list.push({
          name: "Banner",
          url: "/system/banner",
          icon: "icon-basket",
        });

        this.$data.nav.push({
          name: "Тохиргоо",
          icon: "icon-basket",
          url: "/admin/settings",
          children: settings_menu_list,
        });

        this.$data.nav.push({
          name: "Purchase",
          icon: "icon-basket",
          url: "/admin/purchase-parent",
          children: [
            {
              name: "Merchant",
              url: "/admin/purchase/merchant",
              icon: "icon-basket",
            },
            {
              name: "Merchant Category",
              url: "/admin/purchase/merchant/category",
              icon: "icon-basket",
            },
          ],
        });
      }
    },
    checkSession: function() {
      if (this.$store.getters.token != "" && this.$store.getters.user != {}) {
        let _user = this.$store.getters.user;
        this.$http
          .post(
            this.$config.API_URL + "UserWebService/check_session",
            {
              data: JSON.stringify({
                token: this.$store.getters.token,
                email: this.$store.getters.email,
              }),
              role_id: _user.role.id,
            },
            {
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              emulateJSON: true,
            }
          )
          .then(
            (response) => {
              // console.log(response);
              if (response.body == "" || response.body.status > 0) {
                this.$store.commit("DESTROY");
                this.$router.push("/secure/login");
              } else {
                let _user = response.body.data[1];
                this.$store.commit("USER", {
                  role: {
                    id: _user.role,
                    code: _user.code,
                    name: _user.name,
                  },
                  firstname: _user.firstname,
                  lastname: _user.lastname,
                  language: _user.language,
                  email: _user.emailAddress,
                  id: _user.id,
                });
                this.$store.commit("MENU", response.body.data[0]);
                this.$store.commit("LOCALE", _user.language);
                this.$store.commit("PERMISSION", response.body.data[2]);
                this.changeNavs();
                this.loadLoanTypeList(response.body.data[3])
              }
            },
            (response) => {
              this.goError(response);
            }
          );
      } else {
        this.$store.commit("DESTROY");
        this.$router.push("/secure/login");
      }
    },
    back: function() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push("/dashboard");
    },
    goError: function(response) {
      this.$store.commit("DESTROY");
      this.$router.push("/secure/login");
    },
    loadLoanTypeList: function(_loan_type_list)  {
      let list_options = []
      let loan_type_config = {}

      let tmp = null
      let sub_options
      for(var i in _loan_type_list) {
        let item = _loan_type_list[i]

        if(tmp != item['code'].substring(1,2)) {
          if(tmp != null) {
            list_options.push({
              label: '-----',
              options: sub_options
            })
          }
          sub_options = []
          tmp = item['code'].substring(1,2)
        }
      
        sub_options.push(
          {
            id: item['id'],
            text: item['code'] + ' - ' + item['name'],
            value: item['code']
          }
        )

        loan_type_config[item['code']] = item['name']
      }

      list_options.push({
        label: '-----',
        options: sub_options
      })

      this.$store.commit("LOAN_TYPE_OPTIONS", list_options);
      this.$store.commit("LOAN_TYPE", loan_type_config);
    },
    checkNpl: function() {
      let _user = this.$store.getters.user;
      if (_user.role == null) return false;

      return _user.role.code == 'NPL'
    },
    checkPermission: function(_permission) {
      let _roles = ["ADMIN", "SUPER_ADMIN"];
      let _user = this.$store.getters.user;
      if (_user == null) return false;
      if (_user.role == null) return false;
      if (_roles.includes(_user.role.code)) return true;
      let permissions = this.$store.getters.permission;
      if (permissions == undefined) return false;
      return permissions.includes(_permission);
    },

    guard: function(_route) {
      if (_route.meta.permission == undefined) return true;
      return this.checkPermission(_route.meta.permission);
    },

    showToast: function(title, text, variant) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        solid: true,
        appendToast: true,
      });
    },
    decrypt: function(value) {
      return decrypt_data(value);
    },
    encrypt: function(value) {
      return encrypt_data(value);
    },
  },
});
